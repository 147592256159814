/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
  
  $('#topics #add-topic').click(function(){
	  $('#topics .topics').append('<input type="text" name="topic[]" placeholder="Add Topic" />');
	  });

  $(document).ready(function(){
	//mobile menu action
	//open
	$('#drop-the-menu').click(function(e){
		e.preventDefault();
		$('#mobile-menu').stop().fadeIn();
		$('#mobile-menu-back').stop().fadeIn();
		});
	$('#close-mobile-menu').click(function(e){
		e.preventDefault();
		$('#mobile-menu').stop().fadeOut();
		$('#mobile-menu-back').stop().fadeOut();
		});
    $('#mobile-menu-back').click(function(){
		$('#mobile-menu').stop().fadeOut();
		$('#mobile-menu-back').stop().fadeOut();	    
	    });
	//header search drops
	//wordpress search
    $('#open-head-search').click(function(e){
	    e.preventDefault();
	    $('#header-search-retreats, #header-search-venues').slideUp();
	    $('#event-search, #retreat-search').removeClass('active');
		$('#header-search').slideToggle();  	    
	    });
	//events search
    $('#event-search').click(function(e){
	    e.preventDefault();
	    $('#header-search, #header-search-venues').slideUp();
	    $('#retreat-search').removeClass('active');
	    $('#header-search-retreats').slideToggle();
	    $(this).toggleClass('active');
	    });
	//retreat search
    $('#retreat-search').click(function(e){
	    e.preventDefault();
	    $('#header-search, #header-search-retreats').slideUp();
	    $('#event-search').removeClass('active');
	    $('#header-search-venues').slideToggle();
	    $(this).toggleClass('active');
	    });
	//mobile - search event (retreat & event)
    $('#mobile-head-retreat-find').click(function(e){
	    e.preventDefault();
	    $(this).toggleClass('active');
	    $('#mobile-menu #event-search').slideToggle();
	    $('#mobile-head-venue-find').removeClass('active');
	    $('#mobile-menu #venue-search').css('display', 'none');
	    });
	//mobile - search venue (retreat)
    $('#mobile-head-venue-find').click(function(e){
	    e.preventDefault();
	    $(this).toggleClass('active');
	    $('#mobile-menu #venue-search').slideToggle();
	    $('#mobile-head-retreat-find').removeClass('active');
	    $('#mobile-menu #event-search').css('display', 'none');
	    });		
	//date picker desktop
	$( "#head-start-date" ).datepicker({
		dateFormat: 'dd/mm/yy',
		changeMonth: true,
        changeYear: true,
        minDate:0,
        onSelect: function(dateText, inst){
                  $("#head-end-date").datepicker("option","minDate",
                  $("#head-start-date").datepicker("getDate"));
                  }        
		});	
	$( "#head-end-date" ).datepicker({
		dateFormat: 'dd/mm/yy',
		changeMonth: true,
        changeYear: true,
        minDate:0
		});	
	//date picker mobile
	$( "#mob-start-date" ).datepicker({
		dateFormat: 'dd/mm/yy',
		changeMonth: true,
        changeYear: true,
        minDate:0,
        onSelect: function(dateText, inst){
                  $("#mob-end-date").datepicker("option","minDate",
                  $("#mob-start-date").datepicker("getDate"));
                  }         
		});	
	$( "#mob-end-date" ).datepicker({
		dateFormat: 'dd/mm/yy',
		changeMonth: true,
        changeYear: true,
        minDate:0
		});
	//date picker banner search module
	$( "#banner-start" ).datepicker({
		dateFormat: 'dd/mm/yy',
		changeMonth: true,
        changeYear: true,
        minDate:0,
        onSelect: function(dateText, inst){
                  $("#banner-end").datepicker("option","minDate",
                  $("#banner-start").datepicker("getDate"));
                  }      
		});	
	$( "#banner-end" ).datepicker({
		dateFormat: 'dd/mm/yy',
		changeMonth: true,
        changeYear: true,
        minDate:0
		});
	//date picker create standard event
	$( "#standard-start-date" ).datepicker({
		dateFormat: 'dd/mm/yy',
		changeMonth: true,
        changeYear: true,
        minDate: 0,
        onSelect: function(dateText, inst){
                  $("#standard-end-date").datepicker("option","minDate",
                  $("#standard-start-date").datepicker("getDate"));
                  }
		});	
	$( "#standard-end-date" ).datepicker({
		dateFormat: 'dd/mm/yy',
		changeMonth: true,
        changeYear: true,
        minDate: 0
		});
	//date picker create last min event
	$( "#lme-start-date" ).datepicker({
		dateFormat: 'dd/mm/yy',
		changeMonth: true,
        changeYear: true,
        minDate: 0,
        maxDate: 30,
        onSelect: function(dateText, inst){
                  $("#lme-end-date").datepicker("option","minDate",
                  $("#lme-start-date").datepicker("getDate"));
                  }        
		});
	$( "#lme-end-date" ).datepicker({
		dateFormat: 'dd/mm/yy',
		changeMonth: true,
        changeYear: true,
        minDate: 0  
		});

	// check dates in modals are set and submit form
	$('#standardEventModal #submit-event-listing').click(function(e){
		e.preventDefault();
		var stDate = $('#standard-start-date').val();
		var enDate = $('#standard-end-date').val();
		var eTitle = $('#standard-title').val();
		var failed = false;
		if(!stDate.length)
		   {
		   console.log('failed stDate'); 
		   failed = true; 
		   $('#standard-start-date').next().show();
		   }
        if(!enDate.length)
           {
	        console.log('failed enDate');
	        failed = true; 
	        $('#standard-end-date').next().show();   
	       }
        if(!eTitle.length)
           {
	        console.log('failed title');
	        failed = true; 
	        $('#standard-title').next().show();   
	       }
	    //submit if all inputs have a value
	    if(failed == false)
	      {
		   //console.log('submit form'); 
		   $('#standard-listing-form').submit()   
		  }	       
		});
	// check dates in modals are set and submit form
	$('#lmeEventModal #submit-lme-listing').click(function(e){
		e.preventDefault();
		var stDate = $('#lme-start-date').val();
		var enDate = $('#lme-end-date').val();
		var eTitle = $('#lme-title').val();
		var failed = false;
		if(!stDate.length)
		   {
		   console.log('failed stDate'); 
		   failed = true; 
		   $('#lme-start-date').next().show();
		   }
        if(!enDate.length)
           {
	        console.log('failed enDate');
	        failed = true; 
	        $('#lme-end-date').next().show();   
	       }
        if(!eTitle.length)
           {
	        console.log('failed title');
	        failed = true; 
	        $('#lme-title').next().show();   
	       }
	    //submit if all inputs have a value
	    if(failed == false)
	      {
		   //console.log('submit form'); 
		   $('#lme-listing-form').submit()   
		  }	       
		});
	// check title in standard modal is set and submit form
	$('#standardVenueModal #create-standard-listing').click(function(e){
		e.preventDefault();
		var eTitle = $('#standardVenueModal .venue-slug').val();
		var failed = false;
        if(!eTitle.length)
           {
	        console.log('failed title');
	        failed = true; 
	        $('#standardVenueModal .venue-slug').next().show();   
	       }
	    //submit if all inputs have a value
	    if(failed == false)
	      {
		   //console.log('submit form'); 
		   $('#standardVenueModal .creation-form').submit()   
		  }	       
		});	
	// check title in premium modal is set and submit form
	$('#premiumVenueModal #create-premium-listing').click(function(e){
		e.preventDefault();
		var eTitle = $('#premiumVenueModal .venue-slug').val();
		var failed = false;
        if(!eTitle.length)
           {
	        console.log('failed title');
	        failed = true; 
	        $('#premiumVenueModal .venue-slug').next().show();   
	       }
	    //submit if all inputs have a value
	    if(failed == false)
	      {
		   //console.log('submit form'); 
		   $('#premiumVenueModal .creation-form').submit()   
		  }	       
		});				
  }); // end doc ready				
	 /*--------------------------------
		 MODULES
	 ---------------------------------*/
	$(document).ready(function(){
		//banner slider (add on)
		$('.search-banner .banner-slider').slick({
			autoplay: true,
			fade: true,
			arrows: false
		});
		//news slider
		$('.the-news-slider.slick-slider').slick({
		  infinite: true,
		  slidesToShow: 2,
		  slidesToScroll: 2,
		  prevArrow: '<div class="slick-prev"><span></span></div>',
		  nextArrow: '<div class="slick-next"><span></span></div>',
		  responsive: [
		    {
		      breakpoint: 900,
		      settings: {
		        slidesToShow: 1,
		        slidesToScroll: 1,
		      }
		    }
		  ]		  
		}); // end slick
		//retreat gallery slider
		$('.retreat-gallery').slick({
			infinite: true,
			dots: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			prevArrow: '<div class="slick-prev"><span></span></div>',
			nextArrow: '<div class="slick-next"><span></span></div>',
			responsive: [
			  {
				breakpoint: 900,
				settings: {
				  slidesToShow: 1,
				  slidesToScroll: 1,
				}
			  }
			]		  
		  }); // end slick

		  //retreat gallery slider
		$('.retreat-premium-gallery').slick({
			infinite: true,
			arrows: false,
			dots: true,
			slidesToShow: 1,
			slidesToScroll: 1,
			autoplay: true,
			autoplaySpeed: 2000  
		  }); // end slick
		  //retreat location filter
		$('#location-terms li span').click(function(){
			  var $location = $(this).data('id');
			  location.replace("/retreats/?location=" + $location);
			});    
	}); // end doc ready
	/*-------------------------
		retreat single
	--------------------------*/
	$(document).ready(function(){
		$('#galleryModal').on('shown.bs.modal', function () {
			$('#gallery-slider').slick({
				infinite: true,
				slidesToShow: 1,
		        prevArrow: '<div class="slick-prev"><span></span></div>',
		        nextArrow: '<div class="slick-next"><span></span></div>',
	            speed: 500,
	            fade: true,
	            cssEase: 'linear'
				});
			});
		//actions on show and hide video
		$('#videoModal').on('shown.bs.modal', function () {
			var vid = $('#pop-vid').html();
			$('#video-wrapper').html(vid);
			});
		$('#videoModal').on('hide.bs.modal', function () {
			$('#video-wrapper').html('');
		});
		// content tabs scrollto
		$('.content-tabs-section .tab-buttons-holder a').click(function() {
			var linkHref = $(this).attr('href');

			$('html,body').animate({scrollTop: $(linkHref).offset().top - ((($('#banner').css('position') == 'fixed') ? $('#banner').outerHeight() : 0) + ($('.content-tabs-section .tab-buttons-holder').outerHeight() - 2)) }, '800');
		
			return false;
		});
		// content tabs horizontal scroller for overflow
		var slideIndex = 0;
		/*$('.content-tabs-section .tab-buttons-holder-wrapper button').click(function(){
			var $container = $('.tab-buttons-holder');
			var itemWidth = 0;
			$container.find('a').each(function(i, itm){
				itemWidth += $(itm).width();
			});

			console.log('Item Width: ', itemWidth, 'Scrolled: ', scrolled);

			if(itemWidth <= scrolled) {
				scrolled = 0;
				$container.stop().animate({ scrollLeft: 0 });
			}
			else {
				scrolled = scrolled + (itemWidth/5);
				$container.stop().animate({ scrollLeft: scrolled });
			}
			return false;
		});*/
		function scrollToTab(tabHref){
			var $container = $('.tab-buttons-holder');

			var newLeft =  ($container.find('a[href="'+tabHref+'"]').offset().left + $container.scrollLeft()) - $container.offset().left;

			$container.stop().animate({ scrollLeft: newLeft });
		}
		var $lastDiv = null;
		var $buttons = $('.tab-buttons-holder');
		var throttleTimeout = null;
		$(window).on('scroll', function(){
			clearTimeout(throttleTimeout);

			var panelCounter = 0;
			$('.content-tabs-section .tab-content').each(function(i, itm){
				// check if we're scrolled past this, if we are then replace $lastDiv with it
				// check what section is scrolled to in the tabbed content
				if ( $(window).scrollTop() > ($(itm).offset().top - ($('#banner').height() + $buttons.height() + ($(itm).height() / 2))) ){
					$lastDiv = $(itm);

					var $targetBtn = $buttons.find('a[href="#'+$lastDiv.attr('id')+'"]');
					if( $targetBtn.is(':last-child') )
						$('.trigger-scroll').hide();
					else
						$('.trigger-scroll').show();


					panelCounter++;
				}
			});

			// Set first to active if scrolled above the blocks
			if( panelCounter == 0 ){
				$lastDiv = null;
				clearTimeout(throttleTimeout);
				throttleTimeout = null;

				$buttons.stop().animate({ scrollLeft: 0 }, 500);
				$buttons.find('a').removeClass('active');
				$buttons.find('a').first().addClass('active');
			}

			if( $lastDiv ){
				throttleTimeout = setTimeout(function(){
					if( $lastDiv.length > 0 ){
						var $buttons = $('.tab-buttons-holder');
						var $targetBtn = $buttons.find('a[href="#'+$lastDiv.attr('id')+'"]');

						if( $targetBtn.length > 0 && !$targetBtn.hasClass('active') ){
							$buttons.find('a').removeClass('active');
							$targetBtn.addClass('active');
							scrollToTab($targetBtn.attr('href'));
						}
					}
				}, 40);
			}
		});
		//the tabs			
		$('#tab-buttons-holder .tab-button').click(function(e){
			/*e.preventDefault();
			var $id = $(this).data('id');
			$('#tab-buttons-holder .tab-button').removeClass('active');
			$(this).addClass('active');
			$('#tab-panel-holder .tab-panel').removeClass('active');
			$('#tab-panel-holder .tab-panel').css('display', 'none');
			$('#tab-panel-holder #panel-' + $id).fadeIn();*/
			});
		//mobile tab buttons
		$('#tab-ul-holder .holding-text').click(function(){
			$('#tab-ul-holder .tabs-menu').slideToggle();
			$(this).toggleClass('active');
			});
	    //mobile tabs			
		$('#tab-ul-holder .tab-button').click(function(e){
			e.preventDefault();
			var $id = $(this).data('id'); 
			$('#tab-ul-holder .holding-text span').html($(this).html());
			$('#tab-ul-holder .tab-button').removeClass('active');
			$(this).addClass('active');
			$('#tab-panel-holder .tab-panel').removeClass('active');
			$('#tab-panel-holder .tab-panel').css('display', 'none');
			$('#tab-panel-holder #panel-' + $id).fadeIn();
			$('#tab-ul-holder .tabs-menu').fadeOut();
			$('#tab-ul-holder .holding-text').removeClass('active');						
			});
		//scroll to forms
		$('#scroll-to-id').click(function(e){
			e.preventDefault();
		    var jump = $(this).attr('href');
		    var new_position = $(jump).offset();
		    $('html, body').stop().animate({ scrollTop: new_position.top }, 500);
		    });

		//switch forms
		$('#enquire-button-form').click(function(e){
			e.preventDefault();
			$(this).addClass('active');
			$('#feedback-button-form').removeClass('active');
			$('#form-2').css('display', 'none');
			$('#form-1').fadeIn();
			});
		$('#feedback-button-form').click(function(e){
			e.preventDefault();
			$(this).addClass('active');
			$('#enquire-button-form').removeClass('active');
			$('#form-1').css('display', 'none');
			$('#form-2').fadeIn();
			});
			
		//reveal phone number
		$('#track-phone-reveal').click(function(){
			var tel = $('#hidden-phone').val();
			$('#track-phone-link').html(tel);
			$('#track-phone-link').attr('href', 'mailto:' + tel);
			});
		
		//ga tag manager track button clicks	
		$("#see-website").click(function() {
		       gtag('event', 'click', { event_category: 'Visit Website', event_action: 'click', event_label: window.location.href});
		   });
		
		$("#enquire-form-holder").click(function() {
		       gtag('event', 'click', { event_category: 'Reveal Phone Number', event_action: 'click', event_label: window.location.href});
		   });
		   			
		
		//error checking for ACF Input Counter plugin
		//textarea
		$('.acf-input textarea').keyup(function(){
			var ln = 0;
			var maxLn = $(this).attr('maxlength');
			var par;
			if( maxLn != 'undefined' && maxLn != 'null' && maxLn != 0 && maxLn != undefined)
			  {
				ln =  this.value.length;
				
				if(ln <= maxLn)
				  {
				   par = $(this).parent();
				   $('.count', par).html(ln);
				   $(this).removeAttr("disabled");
				  }
			  else{
				   $(this).prop('disabled', true);
				  } 
			  }
			});	
			$('.trigger-scroll').click(function(){
				$(this).parents('.tab-buttons-holder-wrapper').find('a.active').next().click();

				return false;
			});
		//input
		$('.acf-input input[type="text"]').keyup(function(){
			var ln = 0;
			var maxLn = $(this).attr('maxlength');
			var par;
			//console.log(maxLn);
			if( maxLn != 'undefined' && maxLn != 'null' && maxLn != 0 && maxLn != undefined)
			  {
				ln =  this.value.length;
				
				if(ln <= maxLn)
				  {
				   par = $(this).parent().parent();
				   $('.count', par).html(ln);
				   $(this).removeAttr("disabled");
				  }
			  else{
				   $(this).prop('disabled', true);
				  } 
			  }
			});	

		//seemless pagination
		//$('#load-more:not(.active)').click(function(e){
		  $(window).on('resize scroll', function() {
			if($('#load-more').length && $('#load-more:not(.active)').isInViewport())
			  { 				
				var $this = $('#load-more');
				//get the wp-query vars (I hid these in the page, sorry)
				var numOfPages = $('#no-of-pages').val();
				var pageNumber = $('#page-number').val();
			    //make it the next page number 
			    pageNumber++;
			    $('#page-number').val(pageNumber);	
			    $('.loading-icon').show();			
				//check we have more posts
				if(numOfPages > 0 && pageNumber <= numOfPages )
				  {
				   //prevent impatient clicking
				   $($this).addClass('active');
				   //get the base path and remove trailing slash (just in case)
				   var base = window.location.pathname; 
				   if(base.substr(-1) == '/') 
				     {
				      base = base.substr(0, base.length - 1);
				     }
				   //add the paging vars and query string
				   var appendUrl = '/page/'+ pageNumber + '/';			   
				   var queryString = window.location.search;
				   var newUrl = base + appendUrl + queryString;
				   var newContent = ''; 
				   //ajax, get the page
				   $.get(newUrl, function(data){
	                    newContent = $(data).find("#return-posts");
	                    //append as html
	                    $('#return-posts').append($(newContent).html());                   
	                }).done(function(){
			               $($this).removeClass('active');
			               $('.loading-icon').hide();	                
	                });
				  } // end if numOfPages > 0	
			  else{
				  $('#load-more').addClass('active');
				  $('.loading-icon').hide();
				  } // end else	
			   }
			  
			}); // end $('#load-more').click(function(e){
				
        //  slider featured listings
        $('.featured-post-holder .featured-post-slider').slick({
	        arrows: false,
	        dots: true,
	        autoplay: true,
	        fade: true,
	        autoplaySpeed: 6000
            });
				
		}); // end doc ready 
		
   //functions
	$.fn.isInViewport = function() {
		var elementTop = $(this).offset().top;
		var elementBottom = elementTop + $(this).outerHeight();
		var viewportTop = $(window).scrollTop();
		var viewportBottom = viewportTop + $(window).height();
		return elementBottom > viewportTop && elementTop < viewportBottom;
	};		
		
})(jQuery); // Fully reference jQuery after this point.